import React, { useContext } from "react"
import { StateContext } from "../../providers/StateProvider"
import content from "../../content/content.json"
import icons from "../../images/icons/sharing/"
import styles from "../Sharing/Sharing.module.scss"

const SharingOption = ({option, i}) => {
    return(
        <div key={i}>
            <a className={`${styles.Sharing__Option} ${option.name}`} href={option.action} title={option.description}>
                <img className={`${styles.Sharing__Option__Image} ${option.name}`} src={icons[(option.name)]} alt={option.label} />
                <span>{option.label}</span>
            </a>
        </div>
    )
}

const Sharing = React.memo(function Sharing() {
	const { state } = useContext(StateContext)
    const results = content.pages.result
    let context = content.sharing.default
    if (state.progress >= content.question_count) {
        context = (state.true >= results.result1.valid) ? content.sharing.result1 : (state.true >= results.result2.valid) ? content.sharing.result2 : (state.true === results.result3.valid) ? content.sharing.result3 : content.sharing.default
    }

    return (
        <div className={styles.Sharing}>
            <p className={styles.Sharing__Headline}>{content.sharing.headline}</p>
            <div className={styles.Sharing__Options}>
                {context.map((option, i) => 
                    <SharingOption option={option} i={i} key={i} />
                )}
            </div>
        </div>
    );
})

export default Sharing;