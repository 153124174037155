import React from "react"
import Sharing from "../Sharing/"
import styles from "../Footer/Footer.module.scss"

const Module = () => {
    
    const Triangle = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 200" className={styles.Footer__Top__Foreground__Triangle}>
                <polygon className={styles.Footer__Top__Foreground__Triangle__Color} points="1600 200 1600 0 0 200 1600 200"/>
            </svg>
        )
    }

    const SmallTriangle = () => {
        return(
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 200" className={styles.Footer__Top__Background__Top__Triangle}>
                <polygon className={styles.Footer__Top__Background__Top__Triangle__Color} points="1600 200 1600 0 0 200 1600 200"/>
            </svg>
        )
    }

    return (
        <div className={styles.Footer}>
            <div className={styles.Footer__Top}>
                <div className={styles.Footer__Top__Background}>
                    <div className={styles.Footer__Top__Background__Top}>
                        <SmallTriangle />
                    </div>
                    <div className={styles.Footer__Top__Background__Bottom}>
                    </div>
                </div>
                <div className={styles.Footer__Top__Foreground}>
                    <Triangle />
                </div>
            </div>
            <div className={styles.Footer__Bottom}>
                <div className={`${styles.Footer__Bottom__Row} ${styles.Footer__Bottom__Row__Sharing}`}>
                    <Sharing />
                </div>
                <div className={`${styles.Footer__Bottom__Row} ${styles.Footer__Bottom__Row__Imprint}`}>
                    <div className={styles.Footer__Bottom__Row__Item}>
                        <a href="https://einstellungschecker.de/datenschutz">Datenschutz</a>
                    </div>
                    <div className={styles.Footer__Bottom__Row__Item}>
                        <a href="https://einstellungschecker.de/impressum">Impressum</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Module;