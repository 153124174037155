import React, { useContext } from "react"
import { StateContext } from "../../providers/StateProvider"
import Sharing from "../Sharing/"
import styles from "../Header/Header.module.scss"
import content from "../../content/content.json"
import logo from "../../images/bvr.svg"
import einstellungschecker_1 from "../../images/einstellungschecker_1.svg"
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faSearch, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
    
	const { state, actions } = useContext(StateContext)
    const showShare = state.share
    const containerClasses = state.share ? `${styles.Header__SharingContainer} ${styles.Header__SharingContainer__Open}` : `${styles.Header__SharingContainer}`

    const iconOpen = state.share ? "open" : ""

    const ShareIcon = () => {
        return(
            <div className={`${styles.Header__SharingIcon} ${iconOpen}`}>
                <div className={styles.Header__SharingIcon__Dots}>
                    <span className={iconOpen}></span>
                    <span className={iconOpen}></span>
                    <span className={iconOpen}></span>
                </div>
                <div className={styles.Header__SharingIcon__Lines}>
                    <span className={iconOpen}></span>
                    <span className={iconOpen}></span>
                </div>
            </div>
        )
    }

    const ActionShare = () => {
        actions.setShare(!showShare)
    }

    return (
        <header className={styles.Header}>
            <div className={styles.Header__Content}>
                <div className={styles.Header__Content__Logobox}>
                    <a href={content.header_logo_address}>
                        <img src={logo} alt={content.header_logo_alt}/>
                    </a>
                </div>
                <div className={styles.Header__Content__Main}>
                    <a href={"https://einstellungschecker.de"}>
                        <img src={einstellungschecker_1} alt={content.header_logo_alt}/>
                    </a>
                </div>
                <div className={styles.Header__Content__Balance}>
                    <div className={`${styles.Header__Content__Share} ${iconOpen}`} role={"presentation"}
					     onClick={() => ActionShare()} onKeyDown={() => ActionShare()}>
                        <ShareIcon />
                        <span>{"Share"}</span>
                    </div>
                </div>
            </div>
            <aside className={containerClasses}>
                <Sharing />
            </aside>
        </header>
    );
}

export default Header;