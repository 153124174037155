import React from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import content from "../../content/content.json"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"
import "../../style/main.scss"

export default function Layout({ children }) {

	const useScript = url => {
		useEffect(() => {
		  const script = document.createElement("script");
		  script.src = url;
		  script.async = true;
		  document.body.appendChild(script);
		  return () => {
			document.body.removeChild(script);
		  }
		}, [url]);
	  };

	  useScript("https://cdn.tagcommander.com/4483/tc_Einstellungschecker_footer_24.js");

	return (
		<div id="Einstellungschecker">
            
			<Helmet>
				<meta charSet="utf-8" />
				<title>{content.app_title}</title>
				<html lang={"de"} />
				<meta name="color-scheme" content="light only" />
				<meta name="description" content={content.app_description} />
				
				<script type="text/javascript">
					
{`	let tc_vars = {
		env_work: "prod",
		env_country: "DE",
		env_language: "DE",
		env_portal: "einstellungschecker.de",
		page_name: "Einstellungschecker",
	}`}
				</script>
				<script type="text/javascript" src="https://cdn.tagcommander.com/4483/tc_VR_global_header_21.js"></script>
				<script type="text/javascript">
					
{`	// var et_pagename = "";
	// var et_areas = "";
	// var et_tval = 0;
	// var et_tsale = 0;
	// var et_tonr = "";
	// var et_basket = "";`}
				</script>
				<script id="_etLoader" type="text/javascript" charset="UTF-8" data-block-cookies="true" data-respect-dnt="true" data-secure-code="irKBYm" src="//code.etracker.com/code/e.js" async></script>
			</Helmet>

			<Header />
			<div id="content">
				<main>
					{children}
				</main>
				<footer>
					<Footer />
				</footer>
			</div>
		</div>
	)
}
